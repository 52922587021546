.App {
  text-align: center;
}

body, select, button, input {
  font-size: 1em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.message{
  margin: 1em;
  list-style-type: none;
  display: flex;
}

.message-author {
  font-weight: bold;
  color: #0a3e17;
  margin-right: 1em;
  width: 10em;
  flex-basis: 10em;
  flex-shrink: 0;
}

.message-body {
  white-space: pre-wrap;
  margin-right: 1em;
}

.message-delete {
  margin-left: auto;
  padding: 0;
  height: 25px;
  width: 25px;
  flex-shrink: 0;
}

.message.role-assistant .message-author {
  color: #580a6f;
}

.sticker {
  max-width: 150px;
}

#display {
  display: flex;
}

#display > * {
  min-height: 100%;
  width: 600px;
}

button, input, select {
  padding: 5px;
  margin: 5px;
}

.send-input {
  width: 350px;
}

#root {
  display:flex;
  justify-content: space-around;
}

.settings {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display:flex;
  flex-direction: column;
}

.settings input {
  margin-right: 10px;
}